import RequiredValidator from "@/validators/RequiredValidator";
import {NumberValidator} from "@/validators/NumberValidator";

export default class PostageBulkPriceRules {

	public static readonly postage = [
		(new RequiredValidator('postageBulkPrice.singular')).validationRule(),
		(new NumberValidator('postageBulkPrice.singular', true, 0)).validationRule(),
	];

	public static readonly totalGrossWeightFrom = [
		(new RequiredValidator('postageBulkPrice.totalGrossWeightFrom')).validationRule(),
		(new NumberValidator('postageBulkPrice.totalGrossWeightFrom', true, 0)).validationRule(),
	]
}
