<template>
	<div>
		<AsiCard :title="$t('address.terms.shippingAddress')" unwrapped class="overflow-hidden">
			<v-card-text class="pa-0">
				<AsiRadioGroup v-model="shippingAddressId" class="ma-0 pa-0" :disabled="loadingInternal">
					<v-list class="pa-0">
						<v-list-item v-for="address in addresses" :key="`${address.id}-shipping`" @click="shippingAddressId = address.id">
							<v-list-item-action>
								<v-radio :value="address.id"/>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									{{ address.label !== null ? address.label : addressContent(address) }}
								</v-list-item-title>
								<v-list-item-subtitle v-if="address.label !== null">
									{{ addressContent(address) }}
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action v-if="allowModifications">
								<AsiBtn :icon="icons.edit" small @click.stop="updateModel = address; updateDialog.open()"/>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</AsiRadioGroup>

				<template v-if="allowModifications">
					<v-divider/>
					<div class="px-6 py-3 text-center">
						<AsiBtn :icon="icons.create" small @click="createDialog.open()">
							{{ $t('address.terms.create') }}
						</AsiBtn>
					</div>
				</template>
			</v-card-text>
		</AsiCard>
		<AsiCard :title="$t('address.terms.billingAddress')" unwrapped no-bottom-margin class="overflow-hidden">
			<template v-slot:headerActions>
				<AsiCheckbox v-model="sameBillingAddress" :label="$t('address.terms.sameAsShippingAddress')" class="ma-0 pa-0"/>
			</template>

			<v-card-text class="pa-0">
				<v-expand-transition>
					<div v-show="!sameBillingAddress">
						<AsiRadioGroup v-model="billingAddressId" :disabled="loadingInternal" class="ma-0 pa-0">
							<v-list class="pa-0">
								<v-list-item v-for="address in addresses" :key="`${address.id}-billing`" @click="billingAddressId = address.id">
									<v-list-item-action>
										<v-radio :value="address.id"/>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											{{
												address.label !== null ? address.label : addressContent(address)
											}}
										</v-list-item-title>
										<v-list-item-subtitle v-if="address.label !== null">
											{{ addressContent(address) }}
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action v-if="allowModifications">
										<AsiBtn :icon="icons.edit" small @click.stop="updateModel = address; updateDialog.open()"/>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</AsiRadioGroup>

						<template v-if="allowModifications">
							<v-divider/>
							<div class="px-6 py-3 text-center">
								<AsiBtn :icon="icons.create" small @click="createDialog.open()">
									{{ $t('address.terms.create') }}
								</AsiBtn>
							</div>
						</template>
					</div>
				</v-expand-transition>
			</v-card-text>
		</AsiCard>

		<AddressCreateDialog v-if="allowModifications && createDialog.isLoaded"
		                     :open="createDialog.isOpen" :service="$customerServiceShop"
		                     :customer-id="customer.id" :business="isBusiness"
		                     @cancel="createDialog.close()" @save="createDialog.close(); loadAddresses()"/>

		<AddressUpdateDialog v-if="allowModifications && updateDialog.isLoaded && updateModel !== null"
		                     :open="updateDialog.isOpen" :service="$customerServiceShop"
		                     :customer-id="customer.id" :business="isBusiness" :address="updateModel"
		                     @cancel="updateDialog.close()" @save="updateDialog.close(); loadAddresses();"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AddressCreateDialog from "@/components/address/AddressCreateDialog.vue";
	import AddressUpdateDialog from "@/components/address/AddressUpdateDialog.vue";
	import {IAddressFields, IAddressListEntry} from "@/models/address/AddressModels";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import Snackbar from "@/helpers/Snackbar";
	import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import {CustomerType} from "@/helpers/constants";
	import AddressHelper from "@/helpers/AddressHelper";

	@Component({
		components: {AddressUpdateDialog, AddressCreateDialog, AsiBtn, AsiRadioGroup, AsiCard, AsiCheckbox}
	})
	export default class CheckoutWizardStepAddresses extends Vue {

		@Prop({type: Object, required: true})
		public cart!: ICartShopListEntry;

		@Prop({type: Object, required: true})
		public customer!: ICustomerShopSimple;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public allowModifications!: boolean;

		private icons = Icon;
		private loadingInternal: boolean = false;
		private addresses: IAddressListEntry[] = [];
		private sameBillingAddress: boolean = false;
		private createDialog: DialogHandler = new DialogHandler();
		private updateDialog: DialogHandler = new DialogHandler(() => this.updateModel = null);
		private updateModel: IAddressListEntry | null = null;

		private get isBusiness(): boolean {
			return this.customer.type === CustomerType.business;
		}

		private get shippingAddressId(): string | null {
			return this.cart?.customerShippingAddress?.id ?? null;
		}

		private set shippingAddressId(id: string | null) {
			this.loadingInternal = true;
			this.$store.dispatch('cart/updateShippingAddress', {
				cartId: this.cart.id,
				addressId: id,
				addressFields: null
			})
				.catch(() => Snackbar.updateError())
				.finally(() => this.loadingInternal = false);

			if (this.sameBillingAddress && this.cart?.customerBillingAddress?.id !== id) {
				this.billingAddressId = id;
			}
		}

		private get billingAddressId(): string | null {
			return this.cart?.customerBillingAddress?.id ?? null;
		}

		private set billingAddressId(id: string | null) {
			this.loadingInternal = true;
			this.$store.dispatch('cart/updateBillingAddress', {
				cartId: this.cart.id,
				addressId: id,
				addressFields: null
			})
				.catch(() => Snackbar.updateError())
				.finally(() => this.loadingInternal = false);
		}

		@Watch('customer.id', {immediate: true})
		private onCustomerIdChanged(): void {
			this.loadAddresses();
		}

		@Watch('loading', {immediate: true})
		private onLoadingChanged(value: boolean): void {
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		@Emit('loadingChanged')
		private onLoadingInternalChanged(value: boolean): boolean {
			if (this.loading !== value) this.$emit('update:loading', value);
			return value;
		}

		@Watch('sameBillingAddress', {immediate: true})
		private onSameBillingAddressChanged(value: boolean): void {
			const shippingAddressId = this.cart.customerShippingAddress?.id ?? null;
			const billingAddressId = this.cart.customerBillingAddress?.id ?? null;

			if (value && shippingAddressId !== null && shippingAddressId !== billingAddressId) {
				this.loadingInternal = true;
				this.$store.dispatch('cart/updateBillingAddress', {
					cartId: this.cart.id,
					addressId: shippingAddressId,
					addressFields: null
				})
					.catch(() => Snackbar.updateError())
					.finally(() => this.loadingInternal = false);
			}
		}

		private loadAddresses(): void {
			const options = new AsiListTableOptions();
			options.itemsPerPage = 0;

			this.loadingInternal = true;
			this.$customerServiceShop.addresses(this.customer.id, null, options)
				.then(data => this.addresses = data.data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingInternal = false);
		}

		// noinspection JSMethodCanBeStatic
		private addressContent(address: IAddressFields): string {
			return AddressHelper.addressLines(address).join(', ');
		}

	}
</script>

<style lang="scss" scoped>

</style>
