<template>
	<AsiCard unwrapped no-bottom-margin class="flex-grow-1 d-flex flex-column overflow-hidden">
		<div class="content-container" :class="{'is-mobile': sMobile, 'has-image': hasImage | categoryAvatarImage !== null}">
			<template v-if="hasImage">
				<div class="gallery-wrapper">
					<GalleryDetail :image="selectedImage" :aspect-ratio="sMobile ? 2 : 1" :contain="$isMkShop" class="fill-height"/>
					<div v-if="item !== null && item.galleryAttachments.length > 0" class="gallery-preview d-flex flex-row justify-center px-3 py-1">
						<GalleryPreview v-model="selectedImageId" :images="item.galleryAttachments" :small="!sDesktop" dark/>
					</div>
				</div>
				<v-divider class="separator1" :vertical="!sMobile"/>
			</template>
			<template v-else-if="categoryAvatarImage !== null">
				<div class="gallery-wrapper">
					<GalleryDetail :image="categoryAvatarImage" :aspect-ratio="sMobile ? 2 : 1" class="fill-height"/>
				</div>
				<v-divider class="separator1" :vertical="!sMobile"/>
			</template>
			<div class="title-wrapper pa-3">
				<h1>{{ nameTranslated }}</h1>
				<div v-if="item !== null && (item.itemNumber !== null || item.alternativeItemNumber !== null)" class="d-flex flex-row flex-wrap align-center ma-n2">
					<ItemChipNumber v-if="item.itemNumber !== null" :item="item" class="ma-2 px-2" small/>
					<ItemChipAltNumber v-if="item.alternativeItemNumber !== null" :item="item" class="ma-2 px-2" small/>
				</div>
			</div>
			<v-divider v-if="!sMobile" class="vertical-separator1"/>
			<v-divider class="separator2" :vertical="!sMobile"/>
			<div class="quantity-wrapper pa-3 d-flex flex-column">
				<template v-if="item !== null">
					<ItemQuantitySelectionWithPrice :item="item" v-model="quantity" @input="quantityChanged"/>
					<ItemAlertStockStatus class="mt-3" :item="item" no-bottom-margin />
					<ItemAlertStockStatus v-if="showExternalStockQuantity" :show-external-stock-quantity="true" class="mt-3" :item="item" no-bottom-margin />
				</template>
			</div>
			<div class="price-wrapper pa-3">
				<template v-if="hasPrices">
					<AsiBtn small block :text="true" v-if="sMobile" @click="showPriceTable = !showPriceTable">
						<span>{{ $t('itemPrice.terms.showPriceTable') }}</span>
						<v-icon :class="{'flipped': showPriceTable}">{{ icons.dropdown }}</v-icon>
					</AsiBtn>
					<v-expand-transition>
						<ItemPriceTable v-if="item !== null && !sMobile || (sMobile && showPriceTable)" v-model="quantity" :unit="item.salesUnitOfMeasure" :prices="item.prices"/>
					</v-expand-transition>
					<v-expand-transition>
						<AsiAlert v-if="item !== null && item.isTrimmingCostCharged && globalConfig !== null && globalConfig.trimmingCostThreshold !== null" class="mt-2"
						          type="info" :icon="icons.trimmingCostThreshold"
						          dense text no-bottom-margin>
							{{ trimmingCostsInfo }}
						</AsiAlert>
					</v-expand-transition>
				</template>
				<div v-else class="pa-6 grey--text text-center">
					{{ $t('itemPrice.terms.noPrices') }}
				</div>
			</div>
			<v-divider class="vertical-separator2"/>
			<div class="footer-wrapper pa-3 grey lighten-4 d-flex align-center" :class="{'flex-column': sMobile}">
				<div v-if="numCarts > 1 || !sMobile">
					<CartSelectInput v-if="numCarts > 1" v-model="selectedCartId" :class="{'mb-3': sMobile}" :disabled="!hasPrices" simple/>
				</div>
				<div class="button-wrapper"
				     :class="{
					'justify-end': !sMobile,
					'button-container-mobile-no-sample': !canSampleOrder && sMobile,
					'button-container-mobile-has-sample': canSampleOrder && sMobile,
					'button-container-no-sample': !canSampleOrder && !sMobile,
					'button-container-has-sample': canSampleOrder && !sMobile,
					'button-container-mobile-no-watch': !isLoggedIn && sMobile
				}">
					<ShoppingListButton class="watch-btn" v-if="isLoggedIn && item != null" :item-id="item.id" :quantity="quantity" large/>
					<v-tooltip v-if="canSampleOrder" :disabled="!isItemAlreadyInSampleOrderCart" bottom>
						<template v-slot:activator="{ on, attrs }">
							<span class="sample-btn" v-bind="attrs" v-on="on">
							<AsiBtn :disabled="isItemAlreadyInSampleOrderCart" :icon="icons.canOrderSample" outlined color="primary" @click="addToSampleOrderCart()" large>
								{{ $t('item.orderSample') }}
							</AsiBtn>
							</span>
						</template>
						<span>{{ $t('sampleOrder.alreadyAdded') }}</span>
					</v-tooltip>
					<AsiBtn :disabled="!hasPrices" :icon="icons.cart" color="primary" @click="addToCart()" large class="add-btn">
						{{ $t('cart.terms.addToCartDense') }}
					</AsiBtn>
				</div>
			</div>
		</div>

		<v-progress-linear absolute top :indeterminate="loading" :value="100" class="rounded-0"/>

	</AsiCard>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import Icon from "@/plugins/icons";
	import CartSelectInput from "@/components/cart/shop/CartSelectInput.vue";
	import Snackbar from "@/helpers/Snackbar";
	import GalleryDetail from "@/components/gallery/GalleryDetail.vue";
	import {IAttachment} from "@/models/attachment/AttachmentModels";
	import GalleryPreview from "@/components/gallery/GalleryPreview.vue";
	import ItemQuantitySelectionWithPrice from "@/components/item/shop/ItemQuantitySelectionWithPrice.vue";
	import ItemAlertStockStatus from "@/components/item/ItemAlertStockStatus.vue";
	import ItemChipNumber from "@/components/item/ItemChipNumber.vue";
	import ItemChipAltNumber from "@/components/item/ItemChipAltNumber.vue";
	import ShoppingListButton from "@/components/shopping-list/shop/ShoppingListButton.vue";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
	import i18n from "@/plugins/i18n";
	import {TranslateResult} from "vue-i18n";
	import EnumHelper from "@/helpers/EnumHelper";
	import {StockStatus, UnitOfMeasure} from "@/helpers/constants";

	@Component({
		components: {
			AsiAlert,
			ShoppingListButton,
			ItemChipAltNumber,
			ItemChipNumber,
			ItemAlertStockStatus,
			ItemQuantitySelectionWithPrice,
			GalleryPreview,
			GalleryDetail,
			CartSelectInput,
			AsiBtn, AsiCard, ItemPriceTable
		}
	})
	export default class ItemCardBasicData extends mixins(ResponsiveChecks) {

		@Prop({type: Object, default: null})
		public item!: IItemShopListEntry | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		private icons = Icon;
		private quantity: number = 1;
		private showPriceTable: boolean = false;

		private selectedCartId: string | null = null;
		private selectedImageId: string | null = null;
		private currentQuantity: number = 0;

		public created(): void {
			this.selectedCartId = this.$store.state.cart.activeCartId;
		}

		private get hasPrices(): boolean {
			return (this.item?.prices.length ?? 0) > 0;
		}

		private get numCarts(): number {
			return this.$store.state.cart.carts.length;
		}

		private get nameTranslated(): string | null {
			return this.item === null ? null : TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
		}

		private get selectedImage(): IAttachment | null {
			return this.item?.galleryAttachments.find(i => i.id === this.selectedImageId) ?? null;
		}

		private get hasImage(): boolean {
			return (this.item?.galleryAttachments.length ?? 0) > 0;
		}

		private get isLoggedIn(): boolean {
			return this.$store.getters['user/isLoggedIn'];
		}

		private get canSampleOrder(): boolean | undefined {
			return this.item?.canOrderSample;
		}

		private get isItemAlreadyInSampleOrderCart(): boolean {
			return this.$store.getters["sampleOrder/getItemById"](this.item?.id) !== null;
		}

		private get categoryAvatarImage(): IAttachment | null {
			return this.item === null || this.item.category.avatarAttachmentId === null
				? null
				: this.item.category.galleryAttachments.find(i => i.id === this.item?.category.avatarAttachmentId) ?? null;
		}

		private get globalConfig(): IGlobalConfigAdmin | null {
			return this.$store.state.globalConfig.modelAdmin;
		}

		private get trimmingCostsInfo(): TranslateResult {
			const unit = this.item?.salesUnitOfMeasure !== undefined ? EnumHelper.textFromValue(UnitOfMeasure, this.item?.salesUnitOfMeasure, true) : '';
			return i18n.t('item.terms.trimmingCosts', {value: this.globalConfig?.trimmingCostThreshold, unit: unit, price: this.globalConfig?.trimmingCostPrice?.currency.currencyCode + ' ' + this.globalConfig?.trimmingCostPrice?.amount});
		}

		private get showExternalStockQuantity(): boolean {
			return (StockStatus.stockNoInformation !== this.item?.externalStockStatus && StockStatus.stockNone !== this.item?.externalStockStatus)
				&& (this.item?.stockQuantity === null || this.currentQuantity > (this.item?.stockQuantity ?? 0));
		}

		@Watch('item', {deep: true})
		private onItemChanged(): void {
			this.selectedImageId = this.item?.avatarAttachmentId ?? null;
		}

		private addToCart(): void {
			if (this.item !== null) {
				this.$store.dispatch('cart/addPosition', {
					item: this.item,
					quantity: this.quantity,
					cartId: this.selectedCartId,
				})
					.then(() => this.$store.commit('cart/setExpanded', true))
					.catch(() => Snackbar.createError());
			}
		}

		private addToSampleOrderCart(): void {
			if (this.item !== null) {
				this.$store.dispatch('sampleOrder/add', this.item);
			}
		}

		private quantityChanged(currentQuantity: number) {
			this.currentQuantity = currentQuantity;
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../../../node_modules/vuetify/src/styles/styles';

	.content-container {
		display: grid;

		&:not(.is-mobile) {
			&.has-image {
				grid-template:
					"glry sep1 titl titl titl" auto
					"glry sep1 vsp1 vsp1 vsp1" auto
					"glry sep1 qaty sep2 pric" 1fr
					"glry sep1 vsp2 vsp2 vsp2" auto
					"glry sep1 foot foot foot" auto / 25% auto 1fr auto 1fr;
			}

			&:not(.has-image) {
				grid-template:
					"titl titl titl" auto
					"vsp1 vsp1 vsp1" auto
					"qaty sep2 pric" 1fr
					"vsp2 vsp2 vsp2" auto
					"foot foot foot" auto / 1fr auto 1fr;
			}
		}

		&.is-mobile {
			&.has-image {
				grid-template:
					"glry" auto
					"titl" auto
					"qaty" auto
					"foot" auto
					"pric" 1fr / 1fr;
			}

			&:not(.has-image) {
				grid-template:
					"titl" auto
					"qaty" auto
					"foot" auto
					"pric" 1fr / 1fr;
			}
		}

		&.is-mobile {
			.button-container-mobile-has-sample {
				width: 100%;
				grid-template-areas:
					"addC addC"
					"wtch smpl";
				grid-template-columns: 1fr 1fr;
			}

			.button-container-mobile-no-sample {
				width: 100%;
				grid-template-areas:
					"addC"
					"wtch";
				grid-template-columns: 1fr;
			}

			.button-container-mobile-no-watch {
				width: 100%;
				grid-template-areas:
					"addC"
					"smpl";
				grid-template-columns: 1fr;
			}
		}

		&:not(.is-mobile) {
			.button-container-has-sample {
				grid-template-areas:
					"wtch smpl addC";
			}

			.button-container-no-sample {
				grid-template-areas:
					"wtch addC";
			}
		}

		.separator1 {
			grid-area: sep1;
			z-index: 1;
		}

		.separator2 {
			grid-area: sep2;
		}

		.title-wrapper {
			grid-area: titl;
		}

		.gallery-wrapper {
			grid-area: glry;
			position: relative;

			.gallery-preview {
				background-color: rgba(0, 0, 0, 0.5);
				position: absolute;
				bottom: 0;
				width: 100%;
			}
		}

		.quantity-wrapper {
			grid-area: qaty;
		}

		.button-wrapper {
			display: grid;
			grid-gap: $spacer*3;
		}

		.price-wrapper {
			grid-area: pric;

			.flipped {
				transform: rotate(180deg);
			}
		}

		.add-btn {
			grid-area: addC;
		}

		.watch-btn {
			grid-area: wtch;
		}

		.sample-btn {
			grid-area: smpl;

			> button {
				width: 100%;
			}
		}

		.vertical-separator1 {
			grid-area: vsp1;
		}

		.vertical-separator2 {
			grid-area: vsp2;
		}

		&:not(.is-mobile) {
			.footer-wrapper {
				grid-area: foot;

				> * {
					flex: 0 0 50%;
				}
			}
		}

		&.is-mobile {
			.footer-wrapper {
				grid-area: foot;
			}

		}
	}
</style>
