import AddressCreate from "@/models/address/AddressCreate";
import {ValidationRule} from "@/declarations/ValidationRule";
import RequiredValidator from "@/validators/RequiredValidator";
import IFormModel from "@/models/IFormModel";
import {IAddressFields} from "@/models/address/AddressModels";
import {Salutation} from "@/helpers/constants";

export default class SampleOrderAddress implements IFormModel {

	public address!: AddressCreate;
	public email: string | null = null;
	public phone: string | null = null;
	public comment: string | null = null;

	public reset() {
		this.email = null;
		this.phone = null;
		this.comment = null;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'email': {
				return [
					(new RequiredValidator('sampleOrder.email')).validationRule()
				];
			}
		}
		return [];
	}

	constructor() {
		this.address = new AddressCreate();
	}

	public static fromISampleOrderAddressData(addressData: ISampleOrderAddressData): SampleOrderAddress {
		const sampleOrderAddress = new SampleOrderAddress();
		sampleOrderAddress.email = addressData.email;
		sampleOrderAddress.phone = addressData.phone;
		sampleOrderAddress.comment = addressData.comment;

		sampleOrderAddress.address = new AddressCreate();
		sampleOrderAddress.address.label = addressData.address.label;
		sampleOrderAddress.address.salutation = addressData.address.salutation;
		sampleOrderAddress.address.title = addressData.address.title;
		sampleOrderAddress.address.firstname = addressData.address.firstname;
		sampleOrderAddress.address.lastname = addressData.address.lastname;
		sampleOrderAddress.address.company = addressData.address.company;
		sampleOrderAddress.address.companySector = addressData.address.companySector;
		sampleOrderAddress.address.companyAddition = addressData.address.companyAddition;
		sampleOrderAddress.address.role = addressData.address.role;
		sampleOrderAddress.address.address1 = addressData.address.address1;
		sampleOrderAddress.address.address2 = addressData.address.address2;
		sampleOrderAddress.address.street = addressData.address.street;
		sampleOrderAddress.address.houseNumber = addressData.address.houseNumber;
		sampleOrderAddress.address.poBox = addressData.address.poBox;
		sampleOrderAddress.address.zip = addressData.address.zip;
		sampleOrderAddress.address.city = addressData.address.city;
		sampleOrderAddress.address.county = addressData.address.county;
		sampleOrderAddress.address.state = addressData.address.state;
		sampleOrderAddress.address.countryCode = addressData.address.countryCode;

		return sampleOrderAddress;
	}
}

export interface ISampleOrderAddressData {
	address: IAddressFields;
	email: string | null;
	phone: string | null;
	comment: string | null;
}

export class SampleOrderSummaryDisplay {
	public salutation: Salutation | null = null;
	public firstname: string | null = null;
	public lastname: string | null = null;
	public company: string | null = null;
	public companyAddition: string | null = null;
	public street: string | null = null;
	public houseNumber: string | null = null;
	public poBox: string | null = null;
	public zip: string | null = null;
	public city: string | null = null;
	public countryCode: string | null = null;

	public email: string | null = null;
	public phone: string | null = null;
	public comment: string | null = null;

	constructor(s: SampleOrderAddress) {
		if (s.address !== null) {
			this.salutation = s.address?.salutation;
			this.firstname = s.address?.firstname;
			this.lastname = s.address?.lastname;
			this.company = s.address?.company;
			this.companyAddition = s.address?.companyAddition;
			this.street = s.address?.street;
			this.houseNumber = s.address?.houseNumber;
			this.poBox = s.address?.poBox;
			this.zip = s.address?.zip;
			this.city = s.address?.city;
			this.countryCode = s.address?.countryCode;
			this.email = s.email;
			this.phone = s.phone;
			this.comment = s.comment;
		}
	}

}
