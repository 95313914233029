import ModelUpdate from "@/models/ModelUpdate";
import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
import {ValidationRule} from "@/declarations/ValidationRule";
import CategoryHelper from "@/models/category/CategoryHelper";

export default class CategoryUpdateParentCategory extends ModelUpdate<ICategoryAdmin> {

	public newParentId!: string | null;

	public constructor(model: ICategoryAdmin) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: ICategoryAdmin): void {
		this.newParentId = CategoryHelper.parentCategory(detailModel).id;
	}

	public rules(): ValidationRule[] {
		return [];
	}

}
