<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext" :has-filter="hasFilter" :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)" @clearSearch="clearSearch" @update:filterText="filterTextChanged">

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				v-model="selectedItemsInternal"
				:show-select="selectable"
				@click:row="rowClicked">

				<template v-slot:item.avatar="{item}">
					<v-img class="ma-1" :src="avatarUrl(item)" :aspect-ratio="1"/>
				</template>
				<template v-slot:item.name="{item}">
					<div class="d-flex flex-column">
						<span>{{ nameTranslated(item) }}</span>
						<AsiBreadcrumbs :entries="path(item)" hide-icons small/>
					</div>
				</template>
				<template v-slot:item.state="{item}">
					<ItemStateChip :item="item"/>
				</template>

				<template v-slot:item.actions="{item}">
					<slot name="item-actions" :item="item" />
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-model="filterModel.name" :placeholder="$t('item.name')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.externalId" :placeholder="$t('item.externalId')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.itemNumber" :placeholder="$t('item.itemNumber')" clearable/>
			<AsiSelectSimple v-model="filterModel.states" :items="stateOptions" :label="$t('item.state')" :placeholder="$t('item.state')" multiple clearable/>
			<AsiSelectSimple v-model="filterModel.isTopSeller" :items="booleanOptions" :label="$t('item.isTopSeller')" :placeholder="$t('item.isTopSeller')" clearable/>
			<AsiSelectSimple v-model="filterModel.canOrderSample" :items="booleanOptions" :label="$t('item.canOrderSample')" :placeholder="$t('item.canOrderSample')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.metaDescription" :placeholder="$t('ui.terms.metaDescription')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.metaKeywords" :placeholder="$t('ui.terms.metaKeywords')" clearable/>
		</template>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
	import ItemListFilterAdmin from "@/models/item/ItemListFilterAdmin";
	import ItemHelper from "@/models/item/ItemHelper";
	import {AttachmentMediaSize, ItemState} from "@/helpers/constants";
	import Icon from "@/plugins/icons";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import PathEntry from "@/models/PathEntry";
	import ItemStateChip from "@/components/item/ItemStateChip.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";

	@Component({
		components: {
			AsiSelectSimple,
			ItemStateChip,
			AsiBreadcrumbs,
			AsiAvatar,
			AsiContentContainer,
			AsiListTableLayout, AsiTextFieldSimple
		}
	})
	export default class ItemList extends AsiListTable<IItemAdminListEntry, ItemListFilterAdmin> implements IAsiListTableImplementation<IItemAdminListEntry, ItemListFilterAdmin> {

		@Prop({type: String, default: null})
		public categoryId!: string | null;

		@Prop({type: Array, default: null})
		public selectedItems!: IItemAdminListEntry[] | null;

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		@Prop({type: Object, default: () => { return new ItemListFilterAdmin(); }})
		public filter!: ItemListFilterAdmin;

		private icons = Icon;

		private initialized: boolean = false;
		private selectedItemsInternal: IItemAdminListEntry[] = [];

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		private get selectable(): boolean {
			return this.selectedItems !== null;
		}

		private get stateOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(ItemState, true);
		}

		private get booleanOptions(): { text: string, value: boolean }[] {
			return [
				{text: this.$t('ui.yes').toString(), value: true},
				{text: this.$t('ui.no').toString(), value: false},
			];
		}

		private get hasItemActionsSlot(): boolean {
			return !!this.$slots['item-actions'] || !!this.$scopedSlots['item-actions'];
		}

		public createFilterModel(): ItemListFilterAdmin {
			return this.filter;
		}

		public createHeaders(): AsiListTableHeader[] {
			const ret = [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '5rem'),
				new AsiListTableHeader(`${this.$t('item.name')} / ${this.$t('category.terms.path')}`, 'name', true, true, 'start'),
				new AsiListTableHeader(this.$t('item.state'), 'state', true, true, 'center', '10rem'),
			];

			if (this.hasItemActionsSlot) {
				ret.push(new AsiListTableHeader('', 'actions', false, false, 'end', '4rem'));
			}

			return ret;
		}

		public fetchData(filterModel: ItemListFilterAdmin | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IItemAdminListEntry>> {
			return this.categoryId === null
				? this.$itemServiceAdmin.items(filterModel, tableOptions)
				: this.$categoryServiceAdmin.items(this.categoryId, filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		private rowClicked(model: IItemAdminListEntry): void {
			if (!this.selectable) {
				this.openModelDetailPage(model);
			}
		}

		public openModelDetailPage(model: IItemAdminListEntry): void {
			this.$router.push(ItemHelper.detailRouteAdmin(model.id));
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['name'];
				options.sortDesc = [false];
			}
			return options;
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		@Watch('selectedItems', {deep: true, immediate: true})
		private onSelectedItemsChanged(value: IItemAdminListEntry[] | null): void {
		    this.selectedItemsInternal = value !== null ? value : [];
		}

		@Watch('selectedItemsInternal', {deep: true})
		private onselectedItemsInternalChanged(): void {
			this.itemIdsSelected();
		}

		private nameTranslated(item: IItemAdminListEntry): string | null {
			return TranslatedValueHelper.get(item.name, this.$i18n.locale);
		}

		// noinspection JSMethodCanBeStatic
		private color(item: IItemAdminListEntry): string | null {
			return ItemHelper.colorHierarchical(item);
		}

		// noinspection JSMethodCanBeStatic
		private avatarUrl(item: IItemAdminListEntry): string | null {
			return ItemHelper.avatarUrl(item, AttachmentMediaSize.s);
		}

		// noinspection JSMethodCanBeStatic
		private path(item: IItemAdminListEntry): PathEntry[] {
			return ItemHelper.sortedPath(item, true);
		}

		@Emit('selected-ids-changed')
		public itemIdsSelected(): string[] {
			return this.selectedItemsInternal.map(i => i.id);
		}

	}
</script>

<style lang="scss" scoped>

</style>
