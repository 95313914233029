<template>
	<ShopView class="ml-n3" aside-inverted>
		<AsiCard class="mt-n6">
			<AsiRadioGroup v-model="addressType" row class="ma-6">
				<v-radio :value="AddressType.addressBusiness" :label="$t('constants.addressBusiness')" class="mr-6"/>
				<v-radio :value="AddressType.addressPrivate" :label="$t('constants.addressPrivate')"/>
			</AsiRadioGroup>
			<p v-if="addressType == null" class="ms-6">{{ $t('address.terms.chooseAddressTypeToContinue') }}</p>
			<v-divider/>
			<AddressForm v-model="addressFormValid" :model="value.address" :address-type="addressType" flat tile :hide-label="true" :allow-type-selection="false"
			             :hide-default-controls="true" address-type-required @input="updateStoreModel()"/>
			<v-form v-if="addressType != null" v-model="additionalFormValid" class="mt-n10">
				<div class="pa-6">
					<v-row>
						<v-col :cols="sMobile ? 12 : 6">
							<AsiTextField v-model="value.email" :rules="value.rules('email')"
							              :label="$t('sampleOrder.email')" class="input-required" @input="updateStoreModel()"/>
						</v-col>
						<v-col :cols="sMobile ? 12 : 6">
							<AsiTextField v-model="value.phone" :label="$t('sampleOrder.phone')" @input="updateStoreModel()"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextarea v-model="value.comment" :label="$t('sampleOrder.comment')" @input="updateStoreModel()"/>
						</v-col>
					</v-row>
				</div>
				<div class="d-flex flex-column align-center">
					<AsiCheckbox v-model="isTermsAndConditionsAccepted" class="ma-6">
						<template v-slot:label>
							<i18n path="cart.checkout.confirmTermsAndConditions">
								<template v-slot:link>
									<span class="primary--text" style="cursor: pointer" @click="tacDialog.open()">
										{{ $t('cart.checkout.termsAndConditionsAbbreviation') }}
									</span>
								</template>
							</i18n>
						</template>
					</AsiCheckbox>
					<AsiBtn :block="sMobile" class="mb-6" color="primary" :icon="icons.cart" @click="sendSampleOrder()"
					        :disabled="!(valid && isTermsAndConditionsAccepted)">
						{{ $t('sampleOrder.order') }}
					</AsiBtn>
				</div>
			</v-form>
		</AsiCard>
		<AsiDialog v-if="tacDialog.isLoaded" :open="tacDialog.isOpen"
		           :title="$t('pageTitles.shop.termsAndConditions')"
		           :subtitle="tenantName"
		           :icon="icons.toc"
		           @cancel="tacDialog.close()" scrollable>
			<LegalTermsAndConditions/>
		</AsiDialog>
	</ShopView>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AddressForm from "@/components/address/AddressForm.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiTextarea from "@/components/common/AsiTextarea";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import CartContent from "@/components/cart/shop/CartContent.vue";
	import ShopView from "@/components/layout/ShopView.vue";
	import SampleOrderAddress from "@/models/sample-order/SampleOrderAddress";
	import SampleOrderCart from "@/components/sample-order/shop/SampleOrderCart.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import {IItemShopSimple} from "@/models/item/ItemShopModels";
	import Snackbar from "@/helpers/Snackbar";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import {Tenant} from "@/models/ui/Tenant";
	import {AddressType} from "@/helpers/constants";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";

	@Component({
		computed: {
			AddressType() {
				return AddressType;
			}
		},
		components: {
			AsiRadioGroup,
			LegalTermsAndConditions, AsiDialog,
			SampleOrderCart,
			ShopView, CartContent, AsiBtn, AsiCard, AsiCheckbox, AsiTextarea, AsiTextField, AddressForm
		}
	})
	export default class SampleOrderForm extends mixins(ResponsiveChecks) {
		@Prop({type: SampleOrderAddress, required: true})
		public value!: SampleOrderAddress;

		@Prop({type: Boolean, required: true})
		public valid!: boolean;

		private icons = Icon;
		private addressFormValid: boolean = true;
		private additionalFormValid: boolean = true;
		private isTermsAndConditionsAccepted = false;
		private tacDialog: DialogHandler = new DialogHandler();
		private addressType: AddressType | null = null;

		@Watch('addressFormValid', {immediate: true})
		private onAddressFormValidChanged(): void {
			this.change(this.addressFormValid && this.additionalFormValid);
		}

		@Watch('additionalFormValid', {immediate: true})
		private onAdditionalFormValidChanged(): void {
			this.change(this.addressFormValid && this.additionalFormValid);
		}

		@Emit('change')
		public change(valid: boolean): boolean {
			return valid;
		}

		@Emit('performSampleOrder')
		public performSampleOrder(): boolean {
			return true;
		}

		private get tenantName(): string {
			return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
		}

		public get items(): IItemShopSimple[] {
			return this.$store.state.sampleOrder.items;
		}

		private sendSampleOrder(): void {
			if (this.addressFormValid && this.isTermsAndConditionsAccepted) {
				if (this.items.length > 0) {
					this.performSampleOrder();
				} else {
					Snackbar.show(this.$t('sampleOrder.noSampleOrdersInCart'), 'error', 5000);
				}
			}
		}

		private async updateStoreModel() {
			await this.$store.commit('sampleOrderFormData/updateModel', this.value);
		}
	}
</script>

<style lang="scss" scoped>

</style>
