import CategoryListFilter from "@/models/category/CategoryListFilter";
import IPaginatedResponse from "@/models/IPaginatedResponse";
import AsiListTableOptions from "@/components/common/AsiListTableOptions";
import Vue from "vue";
import AxiosService from "@/services/AxiosService";
import {ICategoryAdmin, ICategoryAdminListEntry} from "@/models/category/CategoryAdminModels";
import ItemListFilterAdmin from "@/models/item/ItemListFilterAdmin";
import ICategoryServiceAdmin from "@/services/definition/ICategoryServiceAdmin";
import {IItemAdminListEntry} from "@/models/item/ItemAdminModels";
import CategoryUpdateBasicData from "@/models/category/CategoryUpdateBasicData";
import {CategoryState} from "@/helpers/constants";
import CategoryUpdateItemAttributes from "@/models/category/CategoryUpdateItemAttributes";
import LocaleHelper from "@/helpers/LocaleHelper";
import AttachmentCreate from "@/models/attachment/AttachmentCreate";
import ICreateResponse from "@/models/ICreateResponse";
import CategoryUpdateMetaData from "@/models/category/CategoryUpdateMetaData";
import CategoryUpdateDescription from "@/models/category/CategoryUpdateDescription";
import ItemCreate from "@/models/item/ItemCreate";
import CategoryCreate from "@/models/category/CategoryCreate";
import CategoryUpdateParentCategory from "@/models/category/CategoryUpdateParentCategory";

export default class AxiosCategoryServiceAdmin extends AxiosService implements ICategoryServiceAdmin {


	public static readonly BASE_URL = '/admin/categories';
	public static readonly BASE_URL_COMMON = '/categories';

	protected defineBaseUrl(): string {
		return AxiosCategoryServiceAdmin.BASE_URL;
	}

	public category(id: string): Promise<ICategoryAdmin> {
		return Vue.$axios.get<ICategoryAdmin>(this.url(id)).then(res => res.data);
	}

	public categories(filter: CategoryListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICategoryAdminListEntry>> {
		const opt = new AsiListTableOptions();
		opt.sortBy = options !== null ? options.sortBy : ['name'];
		opt.sortDesc = options !== null ? options.sortDesc : [false];
		opt.page = options !== null ? options.page : 1;
		opt.itemsPerPage = options !== null ? options.itemsPerPage : 0;

		const lang = LocaleHelper.currentLanguage();
		opt.sortBy = opt.sortBy.map(field => {
			return field.startsWith('name') ? `name.${lang}` : field;
		});

		return Vue.$axios.get<IPaginatedResponse<ICategoryAdminListEntry>>(this.url(), {
			params: {
				...filter,
				...opt
			}
		}).then(res => res.data);
	}

	public subCategories(id: string, filter: CategoryListFilter | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<ICategoryAdminListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<ICategoryAdminListEntry>>(this.url(`${id}/categories`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);

	}

	public create(id: string | null = null, model: CategoryCreate): Promise<ICreateResponse> {
		if (id !== null) {
			return Vue.$axios.post<ICreateResponse>(this.url(`${id}/categories`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
		} else {
			return Vue.$axios.post<ICreateResponse>(this.url(``, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
		}
	}

	public updateBasicData(id: string, model: CategoryUpdateBasicData): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/basic-data`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateDescription(id: string, model: CategoryUpdateDescription): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/description`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateMetaData(id: string, model: CategoryUpdateMetaData): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/meta-data`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateParent(id: string, model: CategoryUpdateParentCategory): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/parent`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public updateItemAttributeConfig(id: string, model: CategoryUpdateItemAttributes): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/item-attribute`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}

	public state(id: string, state: CategoryState): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/state`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), {
			state: state,
		}).then(res => res.data);
	}

	public setParent(id: string, parentCategoryId: string): Promise<null> {
		return Vue.$axios.put(this.url(`${id}/state`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), {
			newParentId: parentCategoryId,
		}).then(res => res.data);
	}

	public delete(id: string): Promise<null> {
		return Vue.$axios.delete(this.url(id, AxiosCategoryServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public uploadGalleryImage(model: AttachmentCreate, onProgress: ((event: ProgressEvent) => void)): Promise<ICreateResponse> {
		if (model.model === null || model.file === null) {
			console.error('model and file must be set to upload image gallery attachment');
		}

		const formData = new FormData();
		formData.append('attachment', model.file as Blob, model.file?.name);

		return Vue.$axios.post<ICreateResponse>(this.url(`${model.model?.id}/gallery-attachments`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), formData, {
			onUploadProgress: onProgress,
		}).then(res => res.data);
	}

	public setGalleryImageAvatar(subjectId: string, imageId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${subjectId}/avatar`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), {
			avatarId: imageId,
		}).then(res => res.data);
	}

	public setGalleryImageBanner(subjectId: string, imageId: string | null): Promise<null> {
		return Vue.$axios.put(this.url(`${subjectId}/banner`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), {
			bannerId: imageId,
		}).then(res => res.data);
	}

	public deleteGalleryImage(subjectId: string, imageId: string): Promise<null> {
		return Vue.$axios.delete(this.url(`${subjectId}/gallery-attachments/${imageId}`, AxiosCategoryServiceAdmin.BASE_URL_COMMON)).then(res => res.data);
	}

	public items(id: string, filter: ItemListFilterAdmin | null, options: AsiListTableOptions | null): Promise<IPaginatedResponse<IItemAdminListEntry>> {
		return Vue.$axios.get<IPaginatedResponse<IItemAdminListEntry>>(this.url(`${id}/items`), {
			params: {
				...filter,
				...options
			}
		}).then(res => res.data);
	}

	public createItem(id: string, model: ItemCreate | null): Promise<ICreateResponse> {
		return Vue.$axios.post<ICreateResponse>(this.url(`${id}/items`, AxiosCategoryServiceAdmin.BASE_URL_COMMON), model).then(res => res.data);
	}
}
