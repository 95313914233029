<template>
	<div>
		<v-container class="mx-auto pa-3" style="max-width: 80%;">
			<v-row class="d-flex flex-wrap" justify="center" align="center" no-gutters>
				<v-col v-for="(errorType, index) in errorTypes" :key="index" cols="12" sm="6" md="4" lg="3" class="pa-3">
					<AsiCard unwrapped @click="moveToChapter(errorType.title)" class="d-flex flex-column text-center pa-3 rounded-lg">
						<v-img :src="'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/o-ring-error-types/' + imageFileName(errorType.title) + '_nav.jpg'"
						       :alt="errorType.title"
						       :aspect-ratio="1"
						       class="rounded-t" />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="font-weight-medium text-wrap break-spaces">
									{{ errorType.title }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</AsiCard>
				</v-col>
			</v-row>
		</v-container>
		<v-divider/>
		<template v-for="(errorType, index) in errorTypes">
			<AsiCard :key="index" :title="errorType.title" :ref="errorType.title" title-bg-color="transparent" class="mt-3" wrap-title flat no-bottom-margin>
				<v-card-text v-for="(section, secIndex) in errorType.sections" :key="secIndex">
					<h3>{{section.subtitle}}</h3>
					<p>{{ section.content }}</p>
				</v-card-text>
				<div class="d-grid mx-auto my-5" style="max-width: 800px;">
					<v-img :src="'https://prodchnorth02908337ae324.blob.core.windows.net/herzog-dichtungen-poesia-webshop-assets/o-ring-error-types/' + imageFileName(errorType.title) + '.jpg'"
						:alt="errorType.title"
						class="mx-auto rounded-lg"
						max-width="100%"
						max-height="200px"
						cover/>
				</div>
			</AsiCard>
		</template>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import AsiCard from "@/components/common/AsiCard.vue";

	class errorTypes {
		title!: string;
		sections!: { subtitle: string, content: string }[];
	}

	@Component({
		components: {AsiCard}
	})
	export default class ORingErrorTypesChapters extends Vue {

		private errorTypes!: errorTypes

		public created(): void {
			this.errorTypes = this.$t('errorTypes') as unknown as errorTypes;
		}

		private moveToChapter(title: string): void {
			const targetChapter: any = this.$refs[title];
			if (targetChapter) {
				targetChapter[0].$el.scrollIntoView({behavior: 'smooth'});
			}
		}

		private imageFileName(chapterTitle: string): string {
			return chapterTitle.toLowerCase().replace(/\s+/g, "-");
		}

	}
</script>

<style lang="scss" scoped>

</style>
