import ModelUpdate from "@/models/ModelUpdate";
import {PostageBulkPriceState} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";
import {IPostageBulkPrice, IPostageBulkPriceUpdate} from "@/models/shipment-type/PostageBulkPriceAdminModels";
import PostageBulkPriceRules from "@/models/shipment-type/PostageBulkPriceRules";

export default class PostageBulkPriceUpdate extends ModelUpdate<IPostageBulkPrice | IPostageBulkPriceUpdate> {

	public state: PostageBulkPriceState = PostageBulkPriceState.active;
	public totalGrossWeightFrom: number = 0;
	public totalGrossWeightTo!: number | null;
	public validFrom!: string | null;
	public validTo!: string | null;
	public postage: number = 0;

	public constructor(model: IPostageBulkPrice | IPostageBulkPriceUpdate) {
		super();
		this.setDetailModel(model);
	}

	protected initializeWithDetailModelData(detailModel: IPostageBulkPrice): void {
		this.state = detailModel.state;
		this.totalGrossWeightTo = detailModel.totalGrossWeightTo;
		this.totalGrossWeightFrom = detailModel.totalGrossWeightFrom;
		this.validFrom = detailModel.validFrom;
		this.validTo = detailModel.validTo;
		this.postage = detailModel.postage.amount ?? detailModel.postage;
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'totalGrossWeightFrom':
				return PostageBulkPriceRules.totalGrossWeightFrom;
			case 'postage':
				return PostageBulkPriceRules.postage;
			default:
				return [];
		}
	}
}
