<template>
	<ShopView>
		<h1 class="mb-6 d-flex align-center">
			{{ $t('pageTitles.shop.oRingErrorTypes') }}
		</h1>

		<AsiCard unwrapped>
			<v-card-text class="pa-6">
				<AsiCard :title="this.$t('typicalORingErrorTypes')" title-bg-color="transparent" class="mt-3" wrap-title flat no-bottom-margin>
					<v-card-text class="pl-0 px-md-3">
						<p>{{ this.$t('introText') }}</p>
					</v-card-text>
				</AsiCard>
				<ORingErrorTypesChapters/>
				<v-divider/>
				<AsiCard :title="this.$t('needSupport')" title-bg-color="transparent" class="mt-3" wrap-title flat no-bottom-margin>
					<v-card-text class="pl-0 px-md-3">
						<p>{{ this.$t('needSupportText') }}</p>
					</v-card-text>
				</AsiCard>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import ShopView from "@/components/layout/ShopView.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import ORingErrorTypesChapters from "@/components/o-ring-error-types/ORingErrorTypesChapters.vue";

	@Component({
		components: {ORingErrorTypesChapters, AsiCard, ShopView}
	})
	export default class ORingErrorTypes extends Vue {

	}
</script>

<style lang="scss" scoped>

</style>
