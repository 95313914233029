<template>
	<AsiCardDetail :icon="icons.category" :title="$t('ui.terms.canonical')">
		<AsiCardDetailEntryTranslatedValue :icon="icons.canonical" :label="$t('ui.terms.canonical')" :value="category.canonical"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import {Vue, Component, Prop} from 'vue-property-decorator';
	import CategoryUpdateDialogDescription from "@/components/category/admin/CategoryUpdateDialogDescription.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import Icon from "@/plugins/icons";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";

	@Component({
		components: {AsiCardDetailEntryTranslatedValue, AsiCardDetail, CategoryUpdateDialogDescription}
	})
	export default class CategoryCardCanonical extends Vue {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
	}
</script>

<style scoped lang="scss">

</style>
