<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext"
	                    :has-filter="hasFilter"
	                    :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)"
	                    @clearSearch="clearSearch"
	                    @update:filterText="filterTextChanged">

		<template v-slot:action-buttons>
			<v-tooltip top>
				<template v-slot:activator="{on, attrs}">
					<span v-bind="attrs" v-on="on">
						<AsiBtn :icon="icons.export" @click="performExport"/>
					</span>
				</template>
				{{ $t('ui.terms.exportView') }}
			</v-tooltip>
		</template>

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				@click:row="openModelDetailPage">

				<template v-slot:item.avatar="{item}">
					<AsiAvatar :size="32" :icon="icons.user" :image-url="avatarUrl(item)"/>
				</template>
				<template v-slot:item.customer="{item}">
					<div class="d-flex flex-column">
						<span>{{ item.customer.name }}</span>
						<small class="grey--text">{{ customerTypeLabel(item) }}</small>
					</div>
				</template>
				<template v-slot:item.shippingAddress="{item}">
					<span v-if="item.customer.defaultShippingAddress">
						{{ item.customer.defaultShippingAddress.street }}
						{{ item.customer.defaultShippingAddress.houseNumber }}
						{{ item.customer.defaultShippingAddress.zip }}
						{{ item.customer.defaultShippingAddress.city }}
						{{ item.customer.defaultShippingAddress.countryCode }}
					</span>
					<span v-else>
						-
					</span>
				</template>
				<template v-slot:item.createdAt="{item}">
					<span>
						{{ $d(new Date(item.createdAt), 'short') }} {{ $d(new Date(item.createdAt), 'timeShort') }}
					</span>
				</template>
				<template v-slot:item.state="{item}">
					<UserStateChip :user="item"/>
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-model="filterModel.firstname" :placeholder="$t('user.firstname')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.lastname" :placeholder="$t('user.lastname')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.username" :placeholder="$t('ui.terms.username')" clearable/>
			<AsiSelectSimple v-model="filterModel.states" :items="stateOptions" :placeholder="$t('user.state')" multiple clearable/>
			<AsiTextFieldSimple v-if="customerId === null" v-model="filterModel.customerName" :placeholder="$t('customer.singular')" clearable/>
			<AsiSelectSimple v-if="customerId === null" v-model="filterModel.customerTypes" :items="customerTypeOptions" :placeholder="$t('customer.type')" multiple clearable/>
			<AsiTextFieldSimple v-model="filterModel.notes" :placeholder="$t('user.notes')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.acronym" :placeholder="$t('user.acronym')" clearable/>
		</template>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import {IUserAdminListEntry} from "@/models/user/UserAdminModels";
	import UserListFilter from "@/models/user/UserListFilter";
	import UserHelper from "@/models/user/UserHelper";
	import Icon from "@/plugins/icons";
	import {AttachmentMediaSize, CustomerType, UserState} from "@/helpers/constants";
	import UserStateChip from "@/components/user/UserStateChip.vue";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {
			AsiSelectSimple,
			UserStateChip,
			AsiAvatar,
			AsiContentContainer,
			AsiListTableLayout, AsiTextFieldSimple
		}
	})
	export default class UserList extends AsiListTable<IUserAdminListEntry, UserListFilter> implements IAsiListTableImplementation<IUserAdminListEntry, UserListFilter> {

		@Prop({type: String, default: null})
		public customerId!: string | null;

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		private icons = Icon;
		private initialized: boolean = false;
		private d: Date = new Date();
		private dateString: string = `${this.d.getFullYear().toString().substr(2, 2)}${(this.d.getMonth() + 1).toString().padStart(2, '0')}${this.d.getDate().toString().padStart(2, '0')}`;
		private filename: string = `${this.dateString}_user_export.xlsx`;

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		// noinspection JSMethodCanBeStatic
		private get stateOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(UserState, true);
		}

		// noinspection JSMethodCanBeStatic
		private get customerTypeOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(CustomerType, true);
		}

		public createFilterModel(): UserListFilter {
			return new UserListFilter();
		}

		public createHeaders(): AsiListTableHeader[] {
			const columns = [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
				new AsiListTableHeader(this.$t('user.firstname'), 'firstname', true, true, 'start', '25%'),
				new AsiListTableHeader(this.$t('user.lastname'), 'lastname', true, true, 'start', '25%'),
				new AsiListTableHeader(this.$t('ui.terms.username'), 'username', true, true, 'start'),
			];
			if (this.customerId === null) {
				columns.push(new AsiListTableHeader(this.$t('customer.singular'), 'customer', false, true, 'start', '20%'));
				columns.push(new AsiListTableHeader(this.$t('address.terms.defaultShippingAddress'), 'shippingAddress', false, true, 'start', '20%'));
			}

			columns.push(new AsiListTableHeader(this.$t('customer.createdAt'), 'createdAt', true, true, 'start', '15rem'));
			columns.push(new AsiListTableHeader(this.$t('user.state'), 'state', true, true, 'center', '10rem'));

			return columns;
		}

		public fetchData(filterModel: UserListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IUserAdminListEntry>> {
			return this.customerId === null
				? this.$userServiceAdmin.users(filterModel, tableOptions)
				: this.$customerServiceAdmin.users(this.customerId, filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public openModelDetailPage(model: IUserAdminListEntry): void {
			this.$router.push(UserHelper.detailRouteAdmin(model.id));
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['firstname', 'lastname'];
				options.sortDesc = [false, false];
			}
			return options;
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		// noinspection JSMethodCanBeStatic
		private avatarUrl(user: IUserAdminListEntry): string | null {
			return UserHelper.avatarUrl(user, AttachmentMediaSize.s);
		}

		// noinspection JSMethodCanBeStatic
		private customerTypeLabel(item: IUserAdminListEntry): string | null {
			return EnumHelper.textFromValue(CustomerType, item.customer.type, true);
		}

		private performExport(): void {
			this.loading = true;
			if (this.customerId === null ) {
				this.$userServiceAdmin.exportExcel(this.filterModel, this.tableOptions)
					.then(data => {
						const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
						const link = document.createElement('a');
						link.href = URL.createObjectURL(blob);
						link.download = this.filename;
						link.click();
						URL.revokeObjectURL(link.href);
					})
					.finally(() => this.loading = false);
			} else {
				this.$customerServiceAdmin.exportUsersExcel(this.customerId, this.filterModel, this.tableOptions)
					.then(data => {
						const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
						const link = document.createElement('a');
						link.href = URL.createObjectURL(blob);
						link.download = this.filename;
						link.click();
						URL.revokeObjectURL(link.href);
					})
					.finally(() => this.loading = false);
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
