<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.address" :title="$t('address.terms.create')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<AddressForm v-model="valid" :model="model"
			             :allow-type-selection="allowTypeSelection" :address-type="business ? AddressType.addressBusiness : AddressType.addressPrivate"
			             flat tile no-bottom-margin
			             @requestDefaultShippingAddress="model.isDefaultShippingAddress = $event"
			             @requestDefaultBillingAddress="model.isDefaultBillingAddress = $event"/>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AddressCreate from "@/models/address/AddressCreate";
	import AddressForm from "@/components/address/AddressForm.vue";
	import ICustomerServiceAdmin from "@/services/definition/ICustomerServiceAdmin";
	import ICustomerServiceShop from "@/services/definition/ICustomerServiceShop";
	import {AddressType} from "@/helpers/constants";

	@Component({
		computed: {
			AddressType() {
				return AddressType;
			}
		},
		components: {AddressForm, AsiDialogFormLayout, AsiDialog}
	})
	export default class AddressCreateDialog extends AsiDialogForm {

		@Prop({type: String, required: true})
		public customerId!: string;

		@Prop({type: Object, required: true})
		public service!: ICustomerServiceAdmin | ICustomerServiceShop;

		@Prop({type: Boolean, default: false})
		public business!: boolean;

		@Prop({type: Boolean, default: false})
		public allowTypeSelection!: boolean;

		private model: AddressCreate = new AddressCreate();

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.reset([this.model]);
			return event;
		}

		@Emit('save')
		public save(id: string, defaultChanged: boolean): { id: string; defaultChanged: boolean } {
			this.reset();
			this.model = new AddressCreate();
			return {
				id: id,
				defaultChanged: defaultChanged,
			};
		}

		private requestSave(): void {
			if (!this.valid) return;

			this.loading = true;
			this.service.createAddress(this.customerId, this.model)
				.then(response => this.save(response.id, this.model.isDefaultShippingAddress || this.model.isDefaultBillingAddress))
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
