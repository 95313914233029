<template>
	<AsiCard unwrapped :flat="flat" :tile="tile" :no-bottom-margin="noBottomMargin">
		<template v-slot:title v-if="!hideLabel">
			<AsiTextFieldSimple v-model="model.label"
			                    :label="$t('address.label')" :placeholder="$t('address.label')"/>
		</template>
		<template v-slot:headerActions>
			<AsiBtn v-if="allowDelete" :icon="icons.delete" @click="requestDelete"/>
		</template>
		<v-card-text class="pa-0">
			<template v-if="allowTypeSelection">
				<AsiRadioGroup v-model="addressTypeInternal" row class="ma-6">
					<v-radio :value="AddressType.addressBusiness" :label="$t('constants.addressBusiness')" class="mr-6"/>
					<v-radio :value="AddressType.addressPrivate" :label="$t('constants.addressPrivate')"/>
				</AsiRadioGroup>
				<v-divider/>
			</template>
			<div v-if="addressTypeInternal != null || !addressTypeRequired" >
				<v-form v-model="valueInternal">
					<template v-if="isBusinessAddress">
						<div class="pa-6">
							<v-row>
								<v-col>
									<AsiTextField v-model="model.company" :rules="isBusinessAddress ? model.rules('company') : []"
									              :label="$t('address.company')" :placeholder="$t('address.company')"
									              autocomplete="organization"
									              :clearable="!isBusinessAddress" :class="{'input-required': isBusinessAddress}"/>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<AsiTextField v-model="model.companyAddition" :rules="model.rules('companyAddition')"
									              :label="$t('address.companyAddition')" :placeholder="$t('address.companyAddition')"
									              clearable/>
								</v-col>
							</v-row>
						</div>
						<v-divider/>
					</template>
					<div class="pa-6">
						<v-row>
							<SalutationInput v-model="model.salutation" />
						</v-row>
						<v-row>
							<v-col :cols="sMobile ? 12 : 9/2">
								<AsiTextField v-model="model.firstname" :rules="model.rules('firstname')"
								              :label="$t('address.firstname')" :placeholder="$t('address.firstname')"
								              autocomplete="given-name"
								              class="input-required"/>
							</v-col>
							<v-col :cols="sMobile ? 12 : 9/2">
								<AsiTextField v-model="model.lastname" :rules="model.rules('lastname')"
								              :label="$t('address.lastname')" :placeholder="$t('address.lastname')"
								              autocomplete="family-name"
								              class="input-required"/>
							</v-col>
						</v-row>
					</div>
					<v-divider/>
					<div class="pa-6">
						<v-row>
							<v-col :cols="sMobile ? 12 : 10">
								<AsiTextField v-model="model.street" :rules="model.rules('street')"
								              :label="$t('address.street')" :placeholder="$t('address.street')"
								              class="input-required"/>
							</v-col>
							<v-col :cols="sMobile ? 12 : 2">
								<AsiTextField v-model="model.houseNumber" :rules="model.rules('houseNumber')"
								              :label="$t('address.houseNumber')" :placeholder="$t('address.houseNumber')"/>
							</v-col>
						</v-row>
						<v-row>
							<v-col :cols="12">
								<AsiTextField v-model="model.poBox" :rules="model.rules('poBox')"
								              :label="$t('address.poBox')" :placeholder="$t('address.poBox')"
								              clearable/>
							</v-col>
						</v-row>
						<v-row>
							<v-col :cols="sMobile ? 12 : 6">
								<AsiTextField v-model="model.zip" :rules="model.rules('zip')"
								              :label="$t('address.zip')" :placeholder="$t('address.zip')"
								              autocomplete="postal-code"
								              class="input-required"/>
							</v-col>
							<v-col :cols="sMobile ? 12 : 6">
								<AsiTextField v-model="model.city" :rules="model.rules('city')"
								              :label="$t('address.city')" :placeholder="$t('address.city')"
								              autocomplete="address-level2"
								              class="input-required"/>
							</v-col>
						</v-row>
						<v-row>
							<v-col :cols="12">
								<AsiCountryAutocompleteInput v-model="model.countryCode" :rules="model.rules('countryCode')"
								                             :label="$t('address.countryCode')" :placeholder="$t('address.countryCode')"
								                             autocomplete="country-code"
								                             class="input-required"/>
							</v-col>
						</v-row>
					</div>
					<template v-if="isCreateModel && !hideDefaultControls">
						<v-divider/>
						<div class="pa-6">
							<v-row>
								<v-col class="d-flex flex-row flex-wrap">
									<AsiCheckbox v-model="isDefaultShippingAddress" :label="$t('address.terms.defaultShippingAddress')"
									             class="mr-6 mt-0"/>
									<AsiCheckbox v-model="isDefaultBillingAddress" :label="$t('address.terms.defaultBillingAddress')"
									             class="mt-0"/>
								</v-col>
							</v-row>
						</div>
					</template>
				</v-form>
			</div>
		</v-card-text>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AddressCreate from "@/models/address/AddressCreate";
	import AsiTextField from "@/components/common/AsiTextField";
	import AddressUpdate from "@/models/address/AddressUpdate";
	import AsiCountryAutocompleteInput from "@/components/common/AsiCountryAutocompleteInput.vue";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import SalutationInput from "@/components/common/SalutationInput.vue";
	import {AddressType} from "@/helpers/constants";
	import RequiredValidator from "@/validators/RequiredValidator";

	@Component({
		computed: {
			RequiredValidator() {
				return RequiredValidator;
			},
			AddressType() {
				return AddressType;
			}
		},
		components: {
			SalutationInput,
			AsiCheckbox,
			AsiBtn, AsiTextFieldSimple, AsiCard, AsiRadioGroup, AsiCountryAutocompleteInput, AsiTextField
		}
	})
	export default class AddressForm extends mixins(ResponsiveChecks) {

		@Prop({type: Boolean, default: true})
		public value!: boolean;

		@Prop({type: Object, required: true})
		public model!: AddressCreate | AddressUpdate;

		@Prop({type: Number})
		public addressType!: AddressType;

		@Prop({type: Boolean, default: false})
		public allowDelete!: boolean;

		@Prop({type: Boolean, default: false})
		public flat!: boolean;

		@Prop({type: Boolean, default: false})
		public tile!: boolean;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public allowTypeSelection!: boolean;

		@Prop({type: Boolean, default: false})
		public hideLabel!: boolean;

		@Prop({type: Boolean, default: false})
		public hideDefaultControls!: boolean;

		@Prop({type: Boolean, default: false})
		public addressTypeRequired!: boolean;

		private icons = Icon;
		private valueInternal: boolean = true;
		private addressTypeInternal: AddressType | null = null;

		private get isCreateModel(): boolean {
			return this.model instanceof AddressCreate;
		}

		private get isDefaultShippingAddress(): boolean | null {
			return this.isCreateModel && (this.model as AddressCreate).isDefaultShippingAddress ? true : null;
		}

		private set isDefaultShippingAddress(value: boolean | null) {
			if (!this.isCreateModel) return;
			this.requestIsDefaultShippingAddress(value === true);
		}

		private get isDefaultBillingAddress(): boolean | null {
			return this.isCreateModel && (this.model as AddressCreate).isDefaultBillingAddress ? true : null;
		}

		private set isDefaultBillingAddress(value: boolean | null) {
			if (!this.isCreateModel) return;
			this.requestIsDefaultBillingAddress(value === true);
		}

		private get isBusinessAddress(){
			return this.addressTypeInternal == AddressType.addressBusiness;
		}

		@Watch('value')
		private onValueChanged(value: boolean): void {
			this.valueInternal = value;
		}

		@Watch('valueInternal')
		private onValueInternalChanged(value: boolean): void {
			if (this.value !== value) this.input(value);
		}

		@Watch('addressType', {immediate: true})
		private onAddressTypeChanged(value: AddressType): void {
			this.addressTypeInternal = value;
		}

		@Watch('addressTypeInternal')
		private onAddressTypeInternalChanged(value: AddressType): void {
			if (this.addressType !== value) {
				this.$emit('update:addressType', value);
			}

			if (value !== AddressType.addressBusiness) {
				this.model.company = null;
				this.model.companySector = null;
			}
		}

		@Emit('input')
		public input(value: boolean): boolean {
			return value;
		}

		@Emit('requestDelete')
		public requestDelete(): void {
			return;
		}

		@Emit('requestDefaultShippingAddress')
		public requestIsDefaultShippingAddress(value: boolean | null): boolean {
			return value ?? false;
		}

		@Emit('requestDefaultBillingAddress')
		public requestIsDefaultBillingAddress(value: boolean | null): boolean {
			return value ?? false;
		}

	}
</script>

<style lang="scss" scoped>

</style>
