<template>
	<div></div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiToolbar from "./AsiToolbar.vue";
	import AsiTextFieldSimple from "./AsiTextFieldSimple";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiListTableAdvancedFilters from "@/components/common/AsiListTableAdvancedFilters.vue";
	import IPaginatedResponse from "../../models/IPaginatedResponse";
	import {PerPageOptions} from "@/helpers/constants";
	import IListFilter from "@/models/IListFilter";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import Snackbar from "@/helpers/Snackbar";

	@Component({
		components: {AsiListTableAdvancedFilters, AsiBtn, AsiTextFieldSimple, AsiToolbar}
	})
	export default class AsiListTable<T, U extends IListFilter, V extends IPaginatedResponse<T> = IPaginatedResponse<T>> extends Vue {

		public static readonly DEBOUNCE_MS = 1000;

		@Prop({type: String, default: null})
		public memoryKey!: string | null;

		@Prop({type: String, default: null})
		public memoryKeyFilter!: string | null;

		@Prop({type: String, default: null})
		public memoryKeyOptions!: string | null;

		@Prop({type: String, default: null})
		public memoryKeyId!: string | null;

		@Prop({type: Array, default: () => []})
		public defaultSortBy!: string[];

		@Prop({type: Array, default: () => []})
		public defaultSortDesc!: boolean[];

		@Prop({type: Boolean, default: false})
		public noDebouncing!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		protected data: V | null = null;
		protected filterModel: U | null = null;
		protected tableOptions: AsiListTableOptions = new AsiListTableOptions();
		protected loadingInternal: boolean = false;
		private reloadTimeout: any = null;

		protected get items(): T[] {
			return this.data?.data ?? [];
		}

		protected get total(): number {
			return this.data?.total ?? 0;
		}

		protected get hasFilter(): boolean {
			return this.filterModel?.hasFilter() ?? false;
		}

		protected get perPageOptions(): number[] {
			return PerPageOptions;
		}

		public created(): void {
			this.filterModel = this.createFilterModelInternal();
			this.tableOptions = this.createOptionsInternal();
		}

		// noinspection JSUnusedGlobalSymbols
		public beforeDestroy(): void {
			clearTimeout(this.reloadTimeout);
		}

		@Watch('tableOptions.itemsPerPage')
		private onTableOptionsItemsPerPageChanged(value: number): void {
			this.$store.commit('ui/setItemsPerPage', value);
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChangedBase(value: U): void {
			if (this.memoryKeyFull(false) !== null) {
				this.$store.commit('ui/setListMemoryFilter', {key: this.memoryKeyFull(false), filter: value});
			}
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChangedBase(value: AsiListTableOptions): void {
			if (this.memoryKeyFull(true) !== null) {
				this.$store.commit('ui/setListMemoryOptions', {key: this.memoryKeyFull(true), options: value});
			}
		}

		@Watch('loading')
		private onLoadingChanged(value: boolean): void {
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		private onLoadingInternalChanged(value: boolean): void {
			if (this.loading !== value) this.$emit('update:loading', value);
		}

		protected reloadInternal(implementation: IAsiListTableImplementation<T, U, V>, goToFirstPage: boolean = false, debounce: boolean = true): void {
			clearTimeout(this.reloadTimeout);

			if (debounce && !this.noDebouncing) {
				this.reloadTimeout = setTimeout(() => {
					this.performReload(implementation, goToFirstPage);
				}, AsiListTable.DEBOUNCE_MS);
			} else {
				this.performReload(implementation, goToFirstPage);
			}
		}

		protected performReload(implementation: IAsiListTableImplementation<T, U, V>, goToFirstPage: boolean = false): void {
			if (goToFirstPage) this.tableOptions.page = 1;

			this.loadingInternal = true;
			implementation.fetchData(this.filterModel, this.tableOptions)
				.then(res => {
					this.data = res;
					const numPages = this.tableOptions.itemsPerPage === 0 || this.data.total === 0
						? 1
						: Math.ceil(this.data.total / this.tableOptions.itemsPerPage);

					if (this.tableOptions.page > numPages) {
						this.tableOptions.page = numPages;
						this.performReload(implementation, goToFirstPage);
					}
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingInternal = false);
		}

		protected clearSearch(): void {
			this.filterModel?.clear();
		}

		protected createFilterModel(): U {
			return {} as U;
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			return options;
		}

		protected createFilterModelInternal(): U {
			const filterModel = this.createFilterModel();

			if (this.memoryKeyFull(false) !== null) {
				const memoryData = this.$store.getters['ui/getListMemoryFilter'](this.memoryKeyFull(false)) ?? null;
				if (memoryData !== null) {
					Object.keys(memoryData).forEach(k => {
						//@ts-ignore
						filterModel[k] = memoryData[k];
					});
				}
			}

			return filterModel;
		}

		private createOptionsInternal(): AsiListTableOptions {
			let options: AsiListTableOptions;
			if (this.memoryKeyFull(true) !== null) {
				options = this.$store.getters['ui/getListMemoryOptions'](this.memoryKeyFull(true)) ?? new AsiListTableOptions();
			} else {
				options = new AsiListTableOptions();
			}

			options = this.prepareOptions(options);
			if (options.sortBy.length === 0 && this.defaultSortBy.length > 0) {
				options.sortBy = this.defaultSortBy;
			}
			if (options.sortDesc.length === 0 && this.defaultSortDesc.length > 0) {
				options.sortDesc = this.defaultSortDesc;
			}

			return options;
		}

		private memoryKeyFull(isOptions: boolean): string | null {
			const key = (isOptions ? this.memoryKeyOptions : this.memoryKeyFilter) ?? this.memoryKey ?? null;
			if (key === null) return null;
			return this.memoryKeyId === null ? key : key + '-' + this.memoryKeyId;
		}

	}
</script>

<style lang="scss" scoped>

</style>
