<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.hierarchy" :title="$t('ui.terms.hierarchy')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiSelect clearable
							           :items="categories"
							           v-model="model.newParentId" autocomplete="off"
							           :placeholder="parentCategory"
							           :persistent-placeholder="true"
							           :label="$t('category.parentCategory')" />
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
	import AsiDialogForm from "../../common/AsiDialogForm.vue";
	import AsiTextField from "../../common/AsiTextField";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import CategoryUpdateParentCategory from "@/models/category/CategoryUpdateParentCategory";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import i18n from "@/plugins/i18n";
	import AsiSelect from "@/components/common/AsiSelect";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	@Component({
		components: {AsiBreadcrumbs, AsiSelect, AsiTextField, AsiDialogFormLayout}
	})
	export default class CategoryUpdateDialogParentCategory extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;
		private categories: { text: string; value: number | string }[] = [];

		private model: CategoryUpdateParentCategory | null = null;

		public created(): void {
			this.loadCategories();
		}

		@Watch('category', {deep: true, immediate: true})
		private onCategoryChanged(): void {
			this.setItemInternal();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setItemInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setItemInternal(): void {
			if (this.model === null) {
				this.model = new CategoryUpdateParentCategory(this.category);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.category);
			}
		}

		private loadCategories(): void {
			this.loading = true;
			this.$categoryServiceAdmin.categories(null, null)
				.then(res => {
					this.categories = res.data
						.filter(c => c.id !== this.category.id)
						.map(c => ({ text: TranslatedValueHelper.get(c.name, i18n.locale, true) ?? '?', value: c.id }));
				})
				.finally(() => this.loading = false);
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$categoryServiceAdmin.updateParent(this.category.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		private get parentCategory(): string {
			return CategoryHelper.pathLabel(CategoryHelper.parentCategory(this.category));
		}
	}
</script>

<style lang="scss" scoped>

</style>
