import IFormModel from "@/models/IFormModel";
import {PostageBulkPriceState} from "@/helpers/constants";
import {ValidationRule} from "@/declarations/ValidationRule";
import PostageBulkPriceRules from "@/models/shipment-type/PostageBulkPriceRules";

export default class PostageBulkPriceCreate implements IFormModel {

	public state: PostageBulkPriceState = PostageBulkPriceState.active;
	public postage: number = 0;
	public totalGrossWeightFrom: number = 0;
	public totalGrossWeightTo: number | null = null;
	public validFrom: string | null = null;
	public validTo: string | null = null;

	public reset(): void {
		this.state =  PostageBulkPriceState.active;
		this.postage = 0;
		this.totalGrossWeightFrom = 0;
		this.totalGrossWeightTo = null;
		this.validFrom = null;
		this.validTo = null;
	}

	public rules(property: string): ValidationRule[] {
		switch(property) {
			case 'totalGrossWeightFrom':
				return PostageBulkPriceRules.totalGrossWeightFrom;
			case 'postage':
				return PostageBulkPriceRules.postage;
			default:
				return [];
		}
	}
}
